import React from "react";

import "./assets/css/Footer.css";

function Footer() {
  return (
    <>
      <div className="mt-5">
        <div className="card">
          <div className="row mb-4 ">
            <div className="col-md-6 col-sm-11 col-xs-11 my-3">
              <div className="footer-text pull-left">
                <div className="d-flex">
                  <h4 style={{ color: "white", fontWeight: "700" }}>
                    The Period Society - Assam
                  </h4>
                </div>
                <p style={{ marginTop: "7px" }}>
                  Guwahati, Assam<br></br>
                  <br></br>
                  <strong>Phone:</strong>
                  <a href="tel: 9101295853"> +91 9101295853</a> <br></br>
                  <strong>Email: </strong>
                  <a
                    style={{ fontWeight: "normal" }}
                    href="periodsocietyassam.in@gmail.com"
                  >
                    {" "}
                    periodsocietyassam.in@gmail.com
                  </a>{" "}
                  <br></br>
                </p>
                <div className="social mt-2 mb-3 ">
                  <a
                    style={{ color: "orange" }}
                    href="https://instagram.com/periodsocietyassam?igshid=YmMyMTA2M2Y="
                    target="_blank"
                  >
                    <i className="fa fa-instagram fa-lg"></i> Instagram
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-2 col-sm-4 col-xs-4 my-3"></div>

            <div className="col col-sm-4 col-xs-4 my-3">
              <h5 className="heading">For collaboration related queries:</h5>
              <p>
                {/* (Publicity & Outreach Head, TPS, Assam)
                <br></br> */}
                <strong>Email: </strong>
                <a
                  style={{ fontWeight: "normal" }}
                  href="mailto:  periodsocietyassam.in@gmail.com"
                >
                  periodsocietyassam.in@gmail.com
                </a>{" "}
                <br></br>
              </p>
            </div>
          </div>

          <div className="divider mb-4"></div>

          <div className="row" style={{ fontSize: "10px" }}>
            <div className="col-md-6 col-sm-6 col-xs-6">
              <div className="pull-left">
                <a
                  href="https://wedigtech.netlify.app/"
                  target="_blank"
                  style={{ color: "white" }}
                >
                  <span style={{ color: "#cfcfcf" }}>Developed by:</span> WeDig
                  <span style={{ color: "#ffab04" }}>Tech</span>
                </a>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-xs-6">
              <p>
                <i className="fa fa-copyright"></i> 2023 tps_assam
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
