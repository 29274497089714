import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database"
// import { getStorage } from "firebase/storage"

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBbGejDF6mruhbVUcEJD2PVmvc2IukKTLA",
  authDomain: "finaltps.firebaseapp.com",
  projectId: "finaltps",
  storageBucket: "finaltps.appspot.com",
  messagingSenderId: "192022560568",
  appId: "1:192022560568:web:36966979912ffa1e4a0bcd",
  measurementId: "G-312942GR66"
});

  // Initialize Firebase
  var db = firebaseApp.firestore();
  export const auth = getAuth(firebaseApp);
  export const database = getDatabase(firebaseApp);
  // export const storage = getStorage(firebaseApp);
  export { db };