import React from "react";

function Donate() {
  return (
    <div style={{ minHeight: "54vh", paddingTop: "40vh", textAlign: "center" }}>
      <h4>Page under maintainance</h4>
    </div>
  );
}

export default Donate;
