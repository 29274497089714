import React from 'react'
import GoToTop from "../GoToTop";

function Gallery() {
  return (
    <>
         <GoToTop />    <div style={{ marginTop: "20vh" }}></div>
         <section id="portfolio" className="portfolio">
          <div
            className="content container-fluid"
            style={{ maxWidth: "185vh" }}
          >
            <div className="section-title" data-aos="fade-left">
              <h2
                style={{
                  fontWeight: "bold", color:'#C61E5E',
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Gallery
              </h2>
            </div>
      

            <div className="row my-2">

           


            <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/gallery%2F11.JPG?alt=media&token=8ba44b3c-e643-4e3e-89cc-32c81f8f5236"
                    alt=""
                  />
                </p>
              </div>

    
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F1.JPG?alt=media&token=acc84baf-e861-4f9f-9dfe-bdf1778a4e2a"
                    alt=""
                  />
                </p>
              </div>

          
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F2.JPG?alt=media&token=599ae142-0d2e-46e3-8a69-95ea84921d3e"
                    alt=""
                  />
                </p>
              </div>

         
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F3.JPG?alt=media&token=3c991aa2-2881-4f0b-8ab8-5edc4edceef7"
                    alt=""
                  />
                </p>
              </div>

   
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F4.JPG?alt=media&token=9c627d03-57f0-4afa-b5d5-b6e37dd74d03"
                    alt=""
                  />
                </p>
              </div>

              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F5.JPG?alt=media&token=63243e98-df55-44d3-8838-27c9fb6e831b"
                    alt=""
                  />
                </p>
              </div>

     
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                id="imggg"
                style={{ marginBottom: "20px" }}
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F6.JPG?alt=media&token=a77fc631-3893-42dc-b173-02ad406846e9"
                    alt=""
                  />
                </p>
              </div>

              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F7.JPG?alt=media&token=b49b9bc7-ff25-44a3-aec3-68fa17585ee3"
                    alt=""
                  />
                </p>
              </div>

              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
                style={{ marginBottom: "20px" }}
                id="imggg"
              >
                <p>
                  <img
                    id="images"
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/gallery%2F8.JPG?alt=media&token=90c3844c-a1b0-4bfd-ba21-a7429d7e164b"
                    alt=""
                  />
                </p>
              </div>

            </div>
          </div>
        </section>
    </>
  )
}

export default Gallery
