import React from "react";
import GoToTop from "../GoToTop";
import "../App.css";
import { Card } from "semantic-ui-react";
function Board() {
  return (
    <>
      <GoToTop />
      <div
        className="container-fluid"
        style={{ marginTop: "17vh", maxWidth: "185vh" }}
      >
        <section id="team" className="team">
          <div className="section-title"></div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 my-4">
                <Card style={{ textAlign: "center", width: "100%" }}>
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2Ffounder.jpeg?alt=media&token=0d3c9fec-ca87-482d-9966-f17c1e6cddcd"
                    alt=""
                  />
                  <Card.Content>
                    <Card.Header
                      style={{
                        textTransform: "uppercase",
                        marginBottom: "8px",
                      }}
                    >
                      Swara Patel
                    </Card.Header>
                    <Card.Meta>
                      <span className="position">
                        Founder, The Period Society
                      </span>
                    </Card.Meta>
                  </Card.Content>
                </Card>
              </div>

              <div className="col-lg-3 my-4">
                <Card style={{ textAlign: "center", width: "100%" }}>
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FDr.Rita.JPG?alt=media&token=80424ff3-dd32-4d26-937f-6e43bd6fd495"
                    alt=""
                  />
                  <Card.Content>
                    <Card.Header
                      style={{
                        textTransform: "uppercase",
                        marginBottom: "8px",
                      }}
                    >
                      Dr. Rita Kakati-Shah
                    </Card.Header>
                    <Card.Meta>
                      <span className="position">Advisor</span>
                    </Card.Meta>
                  </Card.Content>
                </Card>
              </div>
            </div>
          </div>
        </section>

        <br></br>
        <br></br>
        <section id="team" className="team">
          <div className="section-title">
            <h2
              style={{
                fontWeight: "bold",
                color: "#C61E5E",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Board Members
            </h2>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 my-4">
                <Card style={{ textAlign: "center", width: "100%" }}>
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FMoushin.JPG?alt=media&token=5bdd665f-f8f1-4458-ac27-c8946c9f0444"
                    alt=""
                  />
                  <Card.Content>
                    <Card.Header
                      style={{
                        textTransform: "uppercase",
                        marginBottom: "8px",
                      }}
                    >
                      Moushin Islam Barbhuyan
                    </Card.Header>
                    <Card.Meta>
                      <span className="position">Founder & State Director</span>
                    </Card.Meta>
                  </Card.Content>
                </Card>
              </div>

              <div className="col-lg-3 my-4">
                <Card style={{ textAlign: "center", width: "100%" }}>
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FLEN_4847.JPG?alt=media&token=1bb92cca-c190-4532-b116-08c7af0a8970"
                    alt=""
                  />
                  <Card.Content>
                    <Card.Header
                      style={{
                        textTransform: "uppercase",
                        marginBottom: "8px",
                      }}
                    >
                      Banashree Kalita
                    </Card.Header>
                    <Card.Meta>
                      <span className="position">Co-Founder</span>
                    </Card.Meta>
                  </Card.Content>
                </Card>
              </div>

              {/* <div className="col-lg-3 my-4">
                    <Card style={{textAlign:'center', width: '100%'}}>
                    <img className="img-fluid"  src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FAditi.JPG?alt=media&token=3300daa1-9f5f-4579-a174-f1fdf1c8d7ad" alt="" />
                    <Card.Content>
                    <Card.Header style={{textTransform:'uppercase',marginBottom:'8px'}}>Aditi Upadhyay</Card.Header>
                    <Card.Meta>
                    <span className='position'>Secretary</span>
                    </Card.Meta>
                    </Card.Content>
                    </Card>
               </div>

               <div className="col-lg-3 my-4">
                    <Card style={{textAlign:'center', width: '100%'}}>
                    <img className="img-fluid"  src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FKanishka.JPG?alt=media&token=e2e6de22-7b6a-4560-b8fb-f5ef40a5ef52" alt="" />
                    <Card.Content>
                    <Card.Header style={{textTransform:'uppercase',marginBottom:'8px'}}>Kanishkna M. Moral</Card.Header>
                    <Card.Meta>
                    <span className='position'>Head of Operation (Internal)</span>
                    </Card.Meta>
                    </Card.Content>
                    </Card>
               </div>

               <div className="col-lg-3 my-4">
                    <Card style={{textAlign:'center', width: '100%'}}>
                    <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FBhargav.JPG?alt=media&token=4ed6556c-add2-46f5-8aae-b20dea36eddd" alt="" />
                    <Card.Content>
                    <Card.Header style={{textTransform:'uppercase',marginBottom:'8px'}}>Bhargav Jyoti Gogoi</Card.Header>
                    <Card.Meta>
                    <span className='position'>Head of Operation (External)</span>
                    </Card.Meta>
                    </Card.Content>
                    </Card>
               </div>

           

               <div className="col-lg-3 my-4">
                    <Card style={{textAlign:'center', width: '100%'}}>
                    <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FArpita.JPG?alt=media&token=a3deb331-d92a-4bc7-a447-d09193b46d15" alt="" />
                    <Card.Content>
                    <Card.Header style={{textTransform:'uppercase',marginBottom:'8px'}}>Arpita Saikia</Card.Header>
                    <Card.Meta>
                    <span className='position'>Social Media Co-ordinator</span>
                    </Card.Meta>
                    </Card.Content>
                    </Card>
               </div>

               <div className="col-lg-3 my-4">
                    <Card style={{textAlign:'center', width: '100%'}}>
                    <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FShrabani.JPG?alt=media&token=fcf85f10-0e4f-4922-9951-48f5ea026c25" alt="" />
                    <Card.Content>
                    <Card.Header style={{textTransform:'uppercase',marginBottom:'8px'}}>Shrabani Das</Card.Header>
                    <Card.Meta>
                    <span className='position'>PR Co-ordinator</span>
                    </Card.Meta>
                    </Card.Content>
                    </Card>
               </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Board;
