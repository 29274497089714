import React from 'react'
import GoToTop from "../GoToTop";

function AboutUs() {
  return (
    <>   <GoToTop /> <div style={{ marginTop: "23vh" }}></div>
        <section id="about" className="about">
        <div className="container-fluid" style={{ maxWidth: "200vh" }}>
          <div className="row">
            <div className="col-lg-4" >
            <img
                   
                    className="img-thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F1.JPG?alt=media&token=acc84baf-e861-4f9f-9dfe-bdf1778a4e2a"
                    alt=""
                  />
            </div>

            <div
              className="col-lg-8 pt-4 pt-lg-0 content"
              id="con"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <h2
                style={{
                  fontWeight: "bold",
                  color:'#C61E5E',
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
              About the organization
              </h2>
     
              <p style={{ textAlign: "justify", fontSize: "14px" }}>
              The cause of The Period Society, Assam is reformation. We're living in the 21st century, the advancements in all spheres, and dynamic interventions have led to development in almost every field. But amidst these wide range of advantages, we lack in breaking the myths and taboos, regarding menstruation. As a youth driven NGO, our pledge as a part of this team is to break the norms, spread awareness and bring the stigma to an end.
<br></br><br></br>
We have a team of 40+ high school students around Assam volunteering, and spearheading our activities as part of our core team. We have served over 348,000 periods which equates to 95,000 menstrual cycles, reached over 5,000 people through its menstrual hygiene education sessions, and met the needs of over 2,000 menstruators for the period of 2 years by distributing reusable cloth pad kits for free within underserved communities in India.
<br></br><br></br>
<strong>The Period Society Assam is an affiliated chapter to The Period Society and registered under Dr. Shanti Patel Memorial Trust (charitable trust in Mumbai, India).
</strong></p>
        
              <br></br>
              {/* <Link  to="/about">
              <Button icon labelPosition='left' size='small' 
             color="orange">
              <Icon name='info'/> Know more <i class="fa fa-angle-double-right" aria-hidden="true"></i>
             </Button></Link> */}
     
            </div>
          </div>
        </div>
      </section><div style={{ marginTop: "17vh" }}></div>
    </>
  )
}

export default AboutUs
