import React from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Contact from "./Components/Contact";
import AboutUs from "./Components/AboutUs";
import Board from "./Components/Board";
import Team from "./Components/Team";
import Gallery from "./Components/Gallery";
import MunMain from "./Components/Mun/MunMain";
import Donate from "./Components/Donate";


function App() {
  return (
   
    <div className="enajori">
    
    <Routes>
       <Route path="/" element={<> <Navbar/><Home/><Footer/></>}/>
       <Route path="/contactus" element={<> <Navbar/><Contact/><Footer/></>}/>
       <Route path="/aboutus" element={<> <Navbar/><AboutUs/><Footer/></>}/>
       <Route path="/team" element={<> <Navbar/><Board/><Team/><Footer/></>}/>
       <Route path="/gallery" element={<> <Navbar/><Gallery/><Footer/></>}/>
       <Route path="/donate" element={<> <Navbar/><Donate/><Footer/></>}/>


       <Route path="/tpsmun" element={<> <Navbar/><MunMain/><Footer/></>}/>
      
     </Routes>
     


    </div>

  );
}

export default App;
