import React from "react";
import { Button, Card, Icon } from "semantic-ui-react";
import GoToTop from "../../GoToTop";

function MunMain() {
  return (
    <>
      {" "}
      <GoToTop />
      <div style={{ marginTop: "20vh" }}></div>
      <section id="about" className="about">
        <div className="container-fluid" style={{ maxWidth: "185vh" }}>
          {/* <div style={{backgroundColor: '#ffe3ea', padding: '15px'}}>
                   <h2
                    style={{
                         fontWeight: "bold",
                         color:'#C61E5E',
                         fontFamily: "Montserrat, sans-serif",
                    }}
                    >
                    Registrations
                    </h2>

        <a href="https://docs.google.com/forms/d/1yPqjIweRQKfV5Y5HeBNoNOZJGbNKk_i_r4J-dNWGeNI/edit?ts=63ff8af9" target="_blank">
              <Button  size='small' 
             style={{backgroundColor:"green", color: 'white'}}>
              Delegates <i class="fa fa-angle-double-right" aria-hidden="true"></i>
             </Button></a>

             <a href="https://docs.google.com/forms/d/1sVzolQXYiUByDM7OESsZLcSGagrOCxqV5reofdhEpfU/edit?usp=drivesdk" target="_blank">
              <Button  size='small' 
             style={{backgroundColor:"orange", color: 'white'}}>
             Campus Ambassador <i class="fa fa-angle-double-right" aria-hidden="true"></i>
             </Button></a>

             </div> */}

          <div className="row my-5">
            <div className="col-lg-4">
              <img
                className="img-thumbnail"
                src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/MUN%2Fcover.jpg?alt=media&token=67623d62-2a92-4052-be49-0ad6d5536d34"
                alt=""
              />
            </div>
            <div
              className="col-lg-8 pt-4 pt-lg-0 content"
              id="con"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <h2
                style={{
                  fontWeight: "bold",
                  color: "#15a2d1",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                What is Model United Nations?
              </h2>
              <p style={{ textAlign: "justify", fontSize: "14px" }}>
                Model United Nations, also known as Model UN or MUN, is an
                educational simulation in which students can learn about
                diplomacy, international relations, and the United Nations. At a
                MUN conference, students work as the representative of a
                country, organization, or person, and must solve a problem with
                other delegates from around the world. MUN teaches participants
                skills like research, public speaking, debating, and writing, in
                addition to critical thinking, teamwork, and leadership. Model
                UN is meant to engage students, and allow them to develop deeper
                understanding into current world issues.
              </p>
            </div>
          </div>

          <div className="row my-5">
            <div
              className="col-lg-8 pt-4 pt-lg-0 content"
              id="con"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <h2
                style={{
                  fontWeight: "bold",
                  color: "#15a2d1",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Vision of TPS, Assam in Model United Nations
              </h2>
              <p style={{ textAlign: "justify", fontSize: "14px" }}>
                The Period Society Assam MUN isn't just any another Private MUN.
                We stand here for a cause, for a change, for the youth. Breaking
                Period Stigma, Gender equality is our main aim and the host of
                the organization. Come and be a part of this Model United
                Nations and take a pledge today to end the stigma around us and
                make it a stigma free zone.{" "}
              </p>
            </div>
            <div className="col-lg-4"></div>
          </div>

          <div className="row my-5">
            <div
              className="col-lg-8 pt-4 pt-lg-0 content"
              id="con"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <h2
                style={{
                  fontWeight: "bold",
                  color: "#15a2d1",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Updates: Committees with Agenda
              </h2>
            </div>

            <section id="team" className="team my-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 my-4">
                    1.
                    <div className="text-center">
                      <img
                        className="img-fluid"
                        style={{ maxWidth: "20vh" }}
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2Funhrc-logo.png?alt=media&token=68671635-9138-44ea-ad43-1a999ca4e888"
                        alt=""
                      />
                      <h2 style={{ color: "#0a7dcf" }}>UNHRC</h2>
                    </div>
                    <h5>Agenda:</h5>
                    <p>
                      Human rights violations in war inflicted regions with
                      special emphasis on the Russia Ukraine war.
                    </p>
                  </div>

                  <div className="col-lg-3 my-4">
                    <Card style={{ textAlign: "center", width: "100%" }}>
                      <img
                        className="img-fluid"
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2F2.JPG?alt=media&token=e276445c-bb3b-44db-aa2e-2369ca6fcf0b"
                        alt=""
                      />
                      <Card.Content>
                        <Card.Header
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "8px",
                          }}
                        >
                          Mridushmoy baruah
                        </Card.Header>
                        <Card.Meta>
                          <span
                            className="position"
                            style={{
                              backgroundColor: "#C61E5E",
                              padding: "8px",
                              color: "white",
                            }}
                          >
                            Chairperson (UNHRC)
                          </span>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </div>

                  <div className="col-lg-3 my-4">
                    <Card style={{ textAlign: "center", width: "100%" }}>
                      <img
                        className="img-fluid"
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2F1.JPG?alt=media&token=9b79129e-46ef-4d3f-83b9-b211263b26e7"
                        alt=""
                      />
                      <Card.Content>
                        <Card.Header
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "8px",
                          }}
                        >
                          Amrita Kashyap
                        </Card.Header>
                        <Card.Meta>
                          <span
                            className="position"
                            style={{
                              backgroundColor: "#C61E5E",
                              padding: "8px",
                              color: "white",
                            }}
                          >
                            Vice-Chairperson (UNHRC)
                          </span>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="row my-5">
            <section id="team" className="team my-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 my-4">
                    2.
                    <div className="text-center">
                      <img
                        className="img-fluid"
                        style={{ maxWidth: "20vh" }}
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2Funchw.png?alt=media&token=1b5ae6c9-7022-4fea-b9fa-e42371dc928c"
                        alt=""
                      />
                      <h2 style={{ color: "#042a91" }}>UNCSW</h2>
                    </div>
                    <h5>Agenda:</h5>
                    <p>
                      Deliberation on the comodification and objectification of
                      women and its impact on their physical and mental health.
                    </p>
                  </div>

                  <div className="col-lg-3 my-4">
                    <Card style={{ textAlign: "center", width: "100%" }}>
                      <img
                        className="img-fluid"
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2F7.JPG?alt=media&token=97615ceb-a0ff-4f91-b38c-750cb1e096b3"
                        alt=""
                      />
                      <Card.Content>
                        <Card.Header
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "8px",
                          }}
                        >
                          Suvankar saha
                        </Card.Header>
                        <Card.Meta>
                          <span
                            className="position"
                            style={{
                              backgroundColor: "#C61E5E",
                              padding: "8px",
                              color: "white",
                            }}
                          >
                            Chairperson (UNCSW)
                          </span>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </div>

                  <div className="col-lg-3 my-4">
                    <Card style={{ textAlign: "center", width: "100%" }}>
                      <img
                        className="img-fluid"
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2F4.JPG?alt=media&token=75b82fdf-daea-47a4-9a7b-050a4e7b0294"
                        alt=""
                      />
                      <Card.Content>
                        <Card.Header
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "8px",
                          }}
                        >
                          Nripangshika Das
                        </Card.Header>
                        <Card.Meta>
                          <span
                            className="position"
                            style={{
                              backgroundColor: "#C61E5E",
                              padding: "8px",
                              color: "white",
                            }}
                          >
                            Vice-Chairperson (UNCSW)
                          </span>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="row my-5">
            <section id="team" className="team my-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 my-4">
                    3.
                    <div className="text-center">
                      <img
                        className="img-fluid"
                        style={{ maxWidth: "20vh" }}
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2FAIPPM.d50096d0.png?alt=media&token=d4279747-c890-42c1-ab85-f8f3181848bc"
                        alt=""
                      />
                      <h2 style={{ color: "#15a2d1" }}>AIPPM</h2>
                    </div>
                    <h5>Agenda:</h5>
                    <p>
                      Undue political interference on Fourth Pillar of Democracy
                      with special emphasis on the current eco-political
                      condition of India
                    </p>
                  </div>

                  <div className="col-lg-3 my-4">
                    <Card style={{ textAlign: "center", width: "100%" }}>
                      <img
                        className="img-fluid"
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2F3.JPG?alt=media&token=1bb43d9f-d57b-438c-9228-594079d97ee5"
                        alt=""
                      />
                      <Card.Content>
                        <Card.Header
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "8px",
                          }}
                        >
                          Abhinav Jyoti Bhuyan
                        </Card.Header>
                        <Card.Meta>
                          <span
                            className="position"
                            style={{
                              backgroundColor: "#C61E5E",
                              padding: "8px",
                              color: "white",
                            }}
                          >
                            Chairperson (AIPPM)
                          </span>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </div>

                  <div className="col-lg-3 my-4">
                    <Card style={{ textAlign: "center", width: "100%" }}>
                      <img
                        className="img-fluid"
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2F10.jpeg?alt=media&token=4098dde1-b12c-4b7d-861e-b8f4479d4bd6"
                        alt=""
                      />
                      <Card.Content>
                        <Card.Header
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "8px",
                          }}
                        >
                          Mrinav Kalita
                        </Card.Header>
                        <Card.Meta>
                          <span
                            className="position"
                            style={{
                              backgroundColor: "#C61E5E",
                              padding: "8px",
                              color: "white",
                            }}
                          >
                            Co-Chairperson (AIPPM)
                          </span>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="row my-5">
            <section id="team" className="team my-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 my-4">
                    4.
                    <div className="text-center">
                      <img
                        className="img-fluid"
                        style={{ maxWidth: "20vh" }}
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2Fipc-logo.png?alt=media&token=314b51cd-2e77-47a1-978e-89783684e8ea"
                        alt=""
                      />
                      <h2 style={{ color: "#15a2d1" }}>International Press</h2>
                    </div>
                  </div>

                  <div className="col-lg-3 my-4">
                    <Card style={{ textAlign: "center", width: "100%" }}>
                      <img
                        className="img-fluid"
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2F6.JPG?alt=media&token=b86c9417-c73b-43f5-b4fb-f929bd1c8a51"
                        alt=""
                      />
                      <Card.Content>
                        <Card.Header
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "8px",
                          }}
                        >
                          Uzma Farhat
                        </Card.Header>
                        <Card.Meta>
                          <span
                            className="position"
                            style={{
                              backgroundColor: "#C61E5E",
                              padding: "8px",
                              color: "white",
                            }}
                          >
                            Editor in Chief
                          </span>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </div>

                  <div className="col-lg-3 my-4">
                    <Card style={{ textAlign: "center", width: "100%" }}>
                      <img
                        className="img-fluid"
                        src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Mun%2F5.JPG?alt=media&token=87f7d40f-3d90-4a33-9348-cafce9db1366"
                        alt=""
                      />
                      <Card.Content>
                        <Card.Header
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "8px",
                          }}
                        >
                          Abhigyan Kashyap
                        </Card.Header>
                        <Card.Meta>
                          <span
                            className="position"
                            style={{
                              backgroundColor: "#C61E5E",
                              padding: "8px",
                              color: "white",
                            }}
                          >
                            Head of Photography
                          </span>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
}

export default MunMain;
