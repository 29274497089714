import React from "react";

import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import { Card } from "semantic-ui-react";

import EEE from "./assets/image/EEE.png";
import NKtv from "./assets/image/NKtv.png";
import LS from "./assets/image/ls.png";
import Rsky from "./assets/image/Rsky.png";
import sh from "./assets/image/sh.png";
import xomonnoy from "./assets/image/xomonnoy.png";
import naayat from "./assets/image/naayat.png";
import enactus from "./assets/image/enactus.png";

function Home() {
  return (
    <>
      <section
        id="hero"
        className="d-flex align-items-center justify-content-center"
      >
        <p
          style={{
            bottom: "-30px",
            position: "absolute",
            backgroundColor: "white",
            padding: "10px",
            margin: "2px",
            border: "2px solid #8DCBE6",
            textAlign: "center",
            borderRadius: "3px",
          }}
        >
          Way more interested to represent and lead your yellow citizen? Here's
          your chance.
          <a
            style={{ marginLeft: "4px" }}
            className="btn btn-warning"
            href="https://docs.google.com/forms/d/1zJskwzPJPqq1o6PtWKRAXR_N_zlyYCaUBNig8oWHn8g/edit?usp=drivesdk"
            target="_blank"
          >
            Apply now!
          </a>
        </p>
        <div className="container" style={{ marginBottom: "30px" }}>
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-8">
              <h1>The Period Society, Assam</h1>
              <h2>#PutAPeriodToTheStigma</h2>
            </div>
          </div>
          <div className="row gy-4 mt-5 justify-content-center"></div>
        </div>
      </section>

      <div style={{ marginTop: "80px" }}></div>
      <section id="about" className="about">
        <div className="container-fluid" style={{ maxWidth: "185vh" }}>
          <div className="row">
            <div
              className="col-lg-10 pt-4 pt-lg-0 content"
              id="con"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <h2
                style={{
                  fontWeight: "bold",
                  color: "#C61E5E",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                About the organization
              </h2>

              <p style={{ textAlign: "justify", fontSize: "14px" }}>
                The cause of The Period Society, Assam is reformation. We're
                living in the 21st century, the advancements in all spheres, and
                dynamic interventions have led to development in almost every
                field. But amidst these wide range of advantages, we lack in
                breaking the myths and taboos, regarding menstruation. As a
                youth driven NGO, our pledge as a part of this team is to break
                the norms, spread awareness and bring the stigma to an end.
                <br></br>
                <br></br>
                We have a team of 40+ high school students around Assam
                volunteering, and spearheading our activities as part of our
                core team. We have served over 348,000 periods which equates to
                95,000 menstrual cycles, reached over 5,000 people through its
                menstrual hygiene education sessions, and met the needs of over
                2,000 menstruators for the period of 2 years by distributing
                reusable cloth pad kits for free within underserved communities
                in India.
                <br></br>
              </p>

              <br></br>
              <Link to="/aboutus">
                <Button
                  icon
                  labelPosition="left"
                  size="small"
                  style={{ backgroundColor: "#C61E5E", color: "white" }}
                >
                  <Icon name="info" /> Know more{" "}
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </Button>
              </Link>
            </div>{" "}
            <div className="col-lg-2" style={{ width: "2vh" }}></div>
          </div>
        </div>
      </section>
      <div style={{ marginTop: "100px" }}></div>

      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
        style={{ backgroundColor: "#E3F6FF", padding: "30px 0 30px 0" }}
      >
        <div className="container-fluid" style={{ maxWidth: "185vh" }}>
          <h2
            style={{
              fontWeight: "bold",
              color: "#C61E5E",
              fontFamily: "Montserrat, sans-serif",
              marginBottom: "15px",
            }}
          >
            Founder's Vision
          </h2>
        </div>

        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div className="container" style={{ maxWidth: "180vh" }}>
              <div className="row">
                <div className="col-lg-3">
                  {" "}
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2Ffounder.jpeg?alt=media&token=0d3c9fec-ca87-482d-9966-f17c1e6cddcd"
                    alt=""
                  />
                </div>
                <div className="col-lg-9">
                  <h3
                    style={{
                      color: "#000",
                      marginTop: "5px",
                      textAlign: "justify",
                    }}
                  >
                    “We should all care about menstrual equality if we truly
                    wish to accelerate gender equality. Each of you can start
                    with a small but powerful step. Drop any euphemisms. Aunt
                    Flo. Shark week. That time of the month. And say the world
                    period aloud.”
                  </h3>
                  <p>
                    Swara Patel - India<br></br>
                    Founder - The Period Society<br></br>
                    One Young World 2022
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div className="container" style={{ maxWidth: "180vh" }}>
              <div className="row">
                <div className="col-lg-3">
                  {" "}
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FMoushin.JPG?alt=media&token=5bdd665f-f8f1-4458-ac27-c8946c9f0444"
                    alt=""
                  />
                </div>
                <div className="col-lg-9">
                  <h5
                    style={{
                      color: "#000",
                      marginTop: "5px",
                      textAlign: "justify",
                    }}
                  >
                    “Menstruation is typically framed as a normal natural
                    biological process, but at the same time, it is also framed
                    as a process that should be kept private or
                    secret-particularly from boys and men. Let's talk period, as
                    a boy, I prefer that the education regarding menstruation is
                    essential not only for women but men too. Spreading
                    awareness and the necessary measures during a woman's
                    menstrual cycle all these factors are of greater importance.
                    Sadly the required form of education is missing associated
                    with menstruation. I came to know more about the need to put
                    an end to the stigma by surfing through social media by
                    trusted sources, such as Wikipedia and channels like
                    Shethepeople TV.
                    <br></br> <br></br>I am proud to be a part of The Period
                    Society, an NGO that deals with the spreading of awareness
                    regarding periods, to break Gender norms to break myths and
                    tabo0s. I also feel that more men and boys should be a part
                    of this NGO, and put our level best to end the stereotypes.
                    All menstruators and non-menstruators should be free enough
                    to share and talk about it with us because it is our
                    responsibility as the youth to spread awareness.”
                  </h5>
                  <br></br>
                  <p>
                    Moushin Islam Barbhuyan<br></br>
                    <span style={{ fontSize: "12px" }}>
                      Founder & State Director<br></br>
                      The Period Society, Assam
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>

      <div style={{ marginTop: "110px" }}></div>

      <section id="portfolio" className="portfolio">
        <div className="content container-fluid" style={{ maxWidth: "185vh" }}>
          <div className="section-title" data-aos="fade-left">
            <h2
              style={{
                fontWeight: "bold",
                color: "#C61E5E",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Gallery
            </h2>
          </div>

          <div className="row my-2">
            {/* <!--1--> */}
            <div
              className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
              style={{ marginBottom: "20px" }}
              id="imggg"
            >
              <p>
                <img
                  id="images"
                  className="img-thumbnail"
                  src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F1.JPG?alt=media&token=acc84baf-e861-4f9f-9dfe-bdf1778a4e2a"
                  alt=""
                />
              </p>
            </div>

            {/* <!--2--> */}
            <div
              className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
              style={{ marginBottom: "20px" }}
              id="imggg"
            >
              <p>
                <img
                  id="images"
                  className="img-thumbnail"
                  src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F2.JPG?alt=media&token=599ae142-0d2e-46e3-8a69-95ea84921d3e"
                  alt=""
                />
              </p>
            </div>

            {/* <!--3--> */}
            <div
              className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
              style={{ marginBottom: "20px" }}
              id="imggg"
            >
              <p>
                <img
                  id="images"
                  className="img-thumbnail"
                  src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F3.JPG?alt=media&token=3c991aa2-2881-4f0b-8ab8-5edc4edceef7"
                  alt=""
                />
              </p>
            </div>

            {/* <!--4--> */}
            <div
              className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
              style={{ marginBottom: "20px" }}
              id="imggg"
            >
              <p>
                <img
                  id="images"
                  className="img-thumbnail"
                  src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F4.JPG?alt=media&token=9c627d03-57f0-4afa-b5d5-b6e37dd74d03"
                  alt=""
                />
              </p>
            </div>

            {/* <!--5--> */}
            <div
              className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
              style={{ marginBottom: "20px" }}
              id="imggg"
            >
              <p>
                <img
                  id="images"
                  className="img-thumbnail"
                  src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F5.JPG?alt=media&token=63243e98-df55-44d3-8838-27c9fb6e831b"
                  alt=""
                />
              </p>
            </div>

            {/* <!--6--> */}
            <div
              className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
              id="imggg"
              style={{ marginBottom: "20px" }}
            >
              <p>
                <img
                  id="images"
                  className="img-thumbnail"
                  src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F6.JPG?alt=media&token=a77fc631-3893-42dc-b173-02ad406846e9"
                  alt=""
                />
              </p>
            </div>

            {/* <!--7--> */}
            <div
              className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
              style={{ marginBottom: "20px" }}
              id="imggg"
            >
              <p>
                <img
                  id="images"
                  className="img-thumbnail"
                  src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/gallery%2F7.JPG?alt=media&token=b49b9bc7-ff25-44a3-aec3-68fa17585ee3"
                  alt=""
                />
              </p>
            </div>

            {/* <!--8--> */}
            <div
              className="col-xl-3 col-lg-3 col-md-6 col-sm-6 m-b-20"
              style={{ marginBottom: "20px" }}
              id="imggg"
            >
              <p>
                <img
                  id="images"
                  className="img-thumbnail"
                  src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/gallery%2F8.JPG?alt=media&token=90c3844c-a1b0-4bfd-ba21-a7429d7e164b"
                  alt=""
                />
              </p>
            </div>

            <div className="container-fluid">
              <br></br>
              <div className="text-right">
                <Link to="/gallery">
                  <Button
                    icon
                    labelPosition="left"
                    size="small"
                    style={{ backgroundColor: "#C61E5E", color: "white" }}
                  >
                    <Icon name="image" /> See more{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style={{ marginTop: "60px" }}></div>

      <section id="about" className="about">
        <div className="container-fluid" style={{ maxWidth: "185vh" }}>
          <h2
            style={{
              fontWeight: "bold",
              color: "#C61E5E",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            The Period Society, Assam - Model United Nations
          </h2>
          <div className="row">
            <div className="col-lg-4">
              <img
                className="img-thumbnail"
                src="https://firebasestorage.googleapis.com/v0/b/tpsassam-48aba.appspot.com/o/MUN%2Fcover.jpg?alt=media&token=67623d62-2a92-4052-be49-0ad6d5536d34"
                alt=""
              />
            </div>
            <div
              className="col-lg-8 pt-4 pt-lg-0 content"
              id="con"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <p style={{ textAlign: "justify", fontSize: "14px" }}>
                Model United Nations, also known as Model UN or MUN, is an
                educational simulation in which students can learn about
                diplomacy, international relations, and the United Nations. At a
                MUN conference, students work as the representative of a
                country, organization, or person, and must solve a problem with
                other delegates from around the world. MUN teaches participants
                skills like research, public speaking, debating, and writing, in
                addition to critical thinking, teamwork, and leadership. Model
                UN is meant to engage students, and allow them to develop deeper
                understanding into current world issues.
                <br></br>
                <br></br>
                {/* The first edition of The Period Society Assam Model United
          Nations to be hosted on: */}
              </p>
              {/* <p>
          Date:{" "}
          <span
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            8th & 9th of April, 2023
          </span>
          <br></br>
          <br></br>
          Venue:{" "}
          <span
            style={{
              backgroundColor: "orange",
              color: "white",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            To be updated
          </span>
        </p> */}
              <br></br>
              <Link to="/tpsmun">
                <Button
                  icon
                  labelPosition="left"
                  size="small"
                  style={{ backgroundColor: "#C61E5E", color: "white" }}
                >
                  <Icon name="info" /> View Details{" "}
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <section style={{ backgroundColor: "#ffe3ea", padding: "30px 0 30px 0" }}>
        <div className="container" style={{ maxWidth: "170vh" }}>
          <div className="row">
            <div className="col-lg-3">
              {" "}
              <img
                className="img-fluid"
                src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2Ffounder.jpeg?alt=media&token=0d3c9fec-ca87-482d-9966-f17c1e6cddcd"
                alt=""
              />
            </div>
            <div className="col-lg-9">
              <h3 style={{ color: "#C61E5E", marginTop: "5px" }}>
                “We should all care about menstrual equality if we truly wish to
                accelerate gender equality. Each of you can start with a small
                but powerful step. Drop any euphemisms. Aunt Flo. Shark week.
                That time of the month. And say the world period aloud.”{" "}
              </h3>
              <p>
                Swara Patel - India<br></br>
                Founder - The Period Society<br></br>
                One Young World 2022
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <div style={{ marginTop: "60px" }}></div>

      <div
        className="container-fluid"
        style={{ marginTop: "8vh", maxWidth: "185vh" }}
      >
        <br></br>
        <br></br>
        <section id="team" className="team">
          <div className="section-title">
            <h2
              style={{
                fontWeight: "bold",
                color: "#C61E5E",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Board Members
            </h2>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 my-4">
                <Card style={{ textAlign: "center", width: "100%" }}>
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FMoushin.JPG?alt=media&token=5bdd665f-f8f1-4458-ac27-c8946c9f0444"
                    alt=""
                  />
                  <Card.Content>
                    <Card.Header
                      style={{
                        textTransform: "uppercase",
                        marginBottom: "8px",
                      }}
                    >
                      Moushin Islam Barbhuyan
                    </Card.Header>
                    <Card.Meta>
                      <span className="position">Founder & State Director</span>
                    </Card.Meta>
                  </Card.Content>
                </Card>
              </div>

              <div className="col-lg-3 my-4">
                <Card style={{ textAlign: "center", width: "100%" }}>
                  <img
                    className="img-fluid"
                    src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FLEN_4847.JPG?alt=media&token=1bb92cca-c190-4532-b116-08c7af0a8970"
                    alt=""
                  />
                  <Card.Content>
                    <Card.Header
                      style={{
                        textTransform: "uppercase",
                        marginBottom: "8px",
                      }}
                    >
                      Banashree Kalita
                    </Card.Header>
                    <Card.Meta>
                      <span className="position">Co-Founder</span>
                    </Card.Meta>
                  </Card.Content>
                </Card>
              </div>

              {/* <div className="col-lg-3 my-4">
                    <Card style={{textAlign:'center', width: '100%'}}>
                    <img className="img-fluid"  src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FAditi.JPG?alt=media&token=3300daa1-9f5f-4579-a174-f1fdf1c8d7ad" alt="" />
                    <Card.Content>
                    <Card.Header style={{textTransform:'uppercase',marginBottom:'8px'}}>Aditi Upadhyay</Card.Header>
                    <Card.Meta>
                    <span className='position'>Secretary</span>
                    </Card.Meta>
                    </Card.Content>
                    </Card>
               </div>

               <div className="col-lg-3 my-4">
                    <Card style={{textAlign:'center', width: '100%'}}>
                    <img className="img-fluid"  src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FKanishka.JPG?alt=media&token=e2e6de22-7b6a-4560-b8fb-f5ef40a5ef52" alt="" />
                    <Card.Content>
                    <Card.Header style={{textTransform:'uppercase',marginBottom:'8px'}}>Kanishkna M. Moral</Card.Header>
                    <Card.Meta>
                    <span className='position'>Head of Operation (Internal)</span>
                    </Card.Meta>
                    </Card.Content>
                    </Card>
               </div>

               <div className="col-lg-3 my-4">
                    <Card style={{textAlign:'center', width: '100%'}}>
                    <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FBhargav.JPG?alt=media&token=4ed6556c-add2-46f5-8aae-b20dea36eddd" alt="" />
                    <Card.Content>
                    <Card.Header style={{textTransform:'uppercase',marginBottom:'8px'}}>Bhargav Jyoti Gogoi</Card.Header>
                    <Card.Meta>
                    <span className='position'>Head of Operation (External)</span>
                    </Card.Meta>
                    </Card.Content>
                    </Card>
               </div>

           

               <div className="col-lg-3 my-4">
                    <Card style={{textAlign:'center', width: '100%'}}>
                    <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FArpita.JPG?alt=media&token=a3deb331-d92a-4bc7-a447-d09193b46d15" alt="" />
                    <Card.Content>
                    <Card.Header style={{textTransform:'uppercase',marginBottom:'8px'}}>Arpita Saikia</Card.Header>
                    <Card.Meta>
                    <span className='position'>Social Media Co-ordinator</span>
                    </Card.Meta>
                    </Card.Content>
                    </Card>
               </div>

               <div className="col-lg-3 my-4">
                    <Card style={{textAlign:'center', width: '100%'}}>
                    <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/finaltps.appspot.com/o/Board%2FShrabani.JPG?alt=media&token=fcf85f10-0e4f-4922-9951-48f5ea026c25" alt="" />
                    <Card.Content>
                    <Card.Header style={{textTransform:'uppercase',marginBottom:'8px'}}>Shrabani Das</Card.Header>
                    <Card.Meta>
                    <span className='position'>PR Co-ordinator</span>
                    </Card.Meta>
                    </Card.Content>
                    </Card>
               </div> */}
            </div>
          </div>
        </section>
      </div>

      <div style={{ marginTop: "60px" }}></div>

      <section id="collaboration" className="collab">
        <div className="container-fluid" style={{ maxWidth: "185vh" }}>
          <div
            className="content"
            id="con"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <h2
              style={{
                fontWeight: "bold",
                color: "#C61E5E",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Our Partners & Collaborations
            </h2>
          </div>

          <div className="row collab">
            <div class="col">
              <img src={EEE} class="img-fluid" alt="Sofy" />
            </div>
            <div class="col">
              <img src={NKtv} class="img-fluid" alt="NitEco" />
            </div>
            <div class="col">
              <img src={naayat} class="img-fluid" alt="UIP" />
            </div>
            <div class="col">
              <img src={enactus} class="img-fluid" alt="UIP" />
            </div>
            <div class="col">
              <img src={LS} class="img-fluid" alt="Progoty" />
            </div>
            <div class="col">
              <img src={Rsky} class="img-fluid" alt="Helping" />
            </div>
            <div class="col">
              <img src={sh} class="img-fluid" alt="SSF" />
            </div>
            <div class="col">
              <img src={xomonnoy} class="img-fluid" alt="UIP" />
            </div>
          </div>
        </div>
      </section>
      <a
        href="https://docs.google.com/forms/d/13nkPyyCL9kBWZ0hVELePF3FsCdwQc3WHpAL3ztjlIgw/edit?usp=drivesdk"
        target="_blank"
      >
        <Button
          color="red"
          style={{
            right: "-65px",
            position: "fixed",
            bottom: "12vh",
            transform: "rotate(-90deg)",
          }}
        >
          Register as Volunteer
        </Button>
      </a>
    </>
  );
}

export default Home;
